<template>
  <v-container fluid>
    <PreviousPage :route="'contratos'"/>
    <v-card :loading="busy">
      <v-card-text v-if="!busy">
        <v-row>
          <template v-for="header in headers">
            <v-col cols="12" md="4" :key="header.value" class="px-5 py-0 ma-0">
              <v-text-field class="textFieldContractDetail" :label="header.text" :value="item[header.value]" readonly dense outlined></v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card :loading="busy" class="mt-2 px-2 py-0">
      <v-card-text v-if="!busy">
        <v-tabs id="contractsTabs" v-model="selectedTab" background-color="transparent" class="pa-0" show-arrows >
          <v-tab href="#tab-readjustment">Reajuste</v-tab>
          <v-tab href="#tab-subs">Subs</v-tab>
          <v-tab v-if="hasPermissionAbaDigitalizados()" href="#tab-digitilized">Digitalizados</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab" class="mt-4" >
          <v-tab-item :value="'tab-subs'">
              <SubList :parentContract="item.id" />
          </v-tab-item>

          <v-tab-item :value="'tab-digitilized'" class="pa-0">
              <v-list-item class="pa-0" v-for="file in item.arquivos" :key="file.id" @click="downloadFile(file)">
                <v-list-item-avatar>
                  <v-icon>{{ file.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ file.name }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn text icon :loading="file.downloading">
                    <v-icon v-if="!file.downloading" @click="downloadFile(file)">fas fa-download</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-card-text v-if="!item.arquivos || !item.arquivos.length">Sem digitalizações</v-card-text>
          </v-tab-item>

          <v-tab-item :value="'tab-readjustment'">
              <Readjustment :contractId="id"/>
          </v-tab-item>

        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SubList from "@/components/contracts/subList.vue";
// import { callsfrest } from "./../../common/sfrest";
import { mapGetters, mapMutations } from "vuex";
/*import { lookup } from "mime-types";
import { saveAs } from "file-saver";*/
import Readjustment from "@/components/contracts/readjustment.vue";
import ContentVersionService from "../../services/odoo/ContentVersionService";
import DmsFileService from "../../services/odoo/DmsFileService";
import ContractService from "../../services/odoo/ContractService";
import PreviousPage from "../../components/structure/previousPage";

export default {
  name: "DetalharContrato",
  components: { SubList, Readjustment, PreviousPage },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: "Empresa", value: "accountName" },
      { text: "Status", value: "status" },
      { text: "CNPJ", value: "accountCNPJ" },
      { text: "Nº Contrato", value: "policy" },
      { text: "Benefício", value: "beneficio" },
      { text: "Operadora", value: "carrierName" },
      { text: "Inicio Vigência", value: "startDate" },
      { text: "Fim Vigência", value: "carrierDataCancelation" },
      { text: "Unidade de Atedimento", value: "executiveManager" },
      { text: "Pagamento", value: "modalidadeDePagamento" },
      { text: "Coparticipação", value: "coparticipacao" },
      { text: "Contributariedade", value: "contributariedade" },
      { text: "Corte", value: "cutOffDate" },
      { text: "Vencimento do Prêmio", value: "vencimentoDoPremio" },
      {
        text: "Vencimento da Coparticipação",
        value: "vencimentoDaCoparticipacao"
      },
      { text: "Limite Técnico", value: "limiteTecnico" },
      { text: "Índice Financeiro", value: "financialIndex" },
    ],
    busy: true,
    selectedTab: "subs",
    contentDocumentIds: [],
    describe: null,
    item: {}
  }),
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    translateStatus(status) {
      if(status === 'active') {
        return "Ativo";
      } else {
        return "Cancelado";
      }
    },
    hasPermissionAbaDigitalizados(){
      if(this.user.authorities){
        let authority = this.user.authorities.filter(a => a.authority === "contratos_aba_digitalizados");
        return authority.length > 0;
      }else
        return false;
    },
    downloadFile(file) {
      this.busy = true;
      /*this._contentVersionService
        .getDownloadFile(file.id)
        .then(attachmentData => {
          saveAs(
            new Blob([attachmentData.data], { type: lookup(file.extension) }),
            file.name
          );
        })
        .finally(() => (this.busy = false));*/

      this._dmsFileService.GetDownloadLinkByFileId(file.id)
          .then(response => {
            if(response && response.data) {
              //window.open(response.data); // open Url
              window.location.assign(response.data); // not open Url
            }
          })
          .finally(() => (this.busy = false))
    },
    configureAttachments(attachmentData) {
      this.item.arquivos = [];
      attachmentData.forEach(_attachmen =>
        this.item.arquivos.push({
          id: _attachmen.id,
          name: _attachmen.name,
          extension: _attachmen && _attachmen.extension ? _attachmen.extension : '',
          icon: _attachmen && _attachmen.extension ? this.convertToIcon(_attachmen.extension) : '',
          downloading: false
        })
      );
      this.orderConfiguredAttachments();
    },
    orderConfiguredAttachments(){
      this.item.arquivos.sort((fileA, fileB) => {
        return fileA.name.localeCompare(fileB.name);
      })
    },
    convertToIcon(fileExtension) {
      let configuredIcon = "";
      const types = {
        word: ["doc", "dot", "webk", "docx", "docm", "dotx", "dotm", "docb"],
        excel: [
          "xls",
          "xlt",
          "xlm",
          "xlsx",
          "xlsm",
          "xltx",
          "xltm",
          "xlsb",
          "xla",
          "xlam",
          "xll",
          "xlw"
        ],
        powerpoint: [
          "ppt",
          "pot",
          "pps",
          "pptx",
          "pptm",
          "potx",
          "potm",
          "ppam",
          "ppsx",
          "ppsm",
          "sldx",
          "sldm"
        ],
        pdf: ["pdf"],
        csv: ["csv"],
        alt: ["txt"],
        image: ["png", "jpg", "jpeg", "svg"]
      };

      for (const typeName in types) {
        if (types[typeName].includes(fileExtension)) {
          configuredIcon = typeName;
        }
      }
      return `fas fa-file${configuredIcon ? `-${configuredIcon}` : ""}`;
    },

    configureItem(itemData) {
      this.item = {
        id: itemData.id,
        accountName: itemData.contract_owner_id.name,
        policy: itemData.policy,
        accountCNPJ: itemData.cnpj,
        status: itemData.state ? this.translateStatus(itemData.state) : ' ',
        beneficio: itemData.benefit_id ? itemData.benefit_id : " ",
        carrierName: itemData.carrier_id.xipp_commercial_name,
        contributariedade: itemData.xipp_contributory_id ? itemData.xipp_contributory_id : " ",
        coparticipacao: itemData.moderator_variable ? itemData.moderator_variable : " ",
        limiteTecnico: itemData.claim_limit ? itemData.claim_limit + "%" : " ",
        modalidadeDePagamento: itemData.payment_mode ? itemData.payment_mode : " ",
        vencimentoDaCoparticipacao: itemData.due_date_copay ? itemData.due_date_copay : " ",
        vencimentoDoPremio: itemData.due_date_invoice ? itemData.due_date_invoice : " ",
        startDate: this.formatDate(itemData.start_date),
        carrierDataCancelation: this.formatDate(itemData.carrier_data_cancelation),
        cutOffDate: itemData.cut_off_date ? itemData.cut_off_date : " ",
        executiveManager: itemData.executive_manager_id ? itemData.executive_manager_id.name : " ",
        financialIndex: itemData.financial_index ? itemData.financial_index : " ",
      };
    },

    async gettContractAttachments() {
      /*await this._contentVersionService.FindAll(this.id, "xipp.contract").then(res => {
        return this.configureAttachments(res.data.records);
      });*/

      const params = {
        modelName: 'xipp.contract',
        recordId: this.id
      }

      await this._dmsFileService.GetAllFilesFromStorage(params)
          .then(response => {
            return this.configureAttachments(response.data);
          });
    },

    async getcontractPorId() {
      const params = new URLSearchParams();
      params.append('id', this.id);
      params.append('fields', ['id', 'contract_owner_id', 'policy', 'cnpj', 'state',
        'benefit_id', 'carrier_id', 'xipp_contributory_id', 'moderator_variable', 'claim_limit',
        'payment_mode', 'due_date_copay', 'due_date_invoice', 'start_date', 'carrier_data_cancelation',
        'cut_off_date', 'executive_manager_id', 'financial_index']);
      this.busy = true;
      await this._contractService
        .findContractsByFilters(params)
        .then(res => {
            this.configureItem(res.data[0]);
        })
        .finally(() => (this.busy = false));
    },
    formatDate(date){
      return date ? Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date)) : " ";
    }
  },
  async created() {
    try{
      this.loading(true);

      this._contentVersionService = new ContentVersionService();
      this._dmsFileService = new DmsFileService();
      this._contractService = new ContractService();

      await this.getcontractPorId();
      await this.gettContractAttachments();
    }finally {
      this.loading(false);
    }
  }
};
</script>

<style>
  .v-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  }

  .textFieldContractDetail.v-text-field input {
    margin-top: 10px ;
    margin-bottom: 10px ;
  }

  #contractsTabs .v-slide-group__prev--disabled{
    display: none;
  }




</style>
