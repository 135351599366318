<template>
  <v-container fluid grid-list-md class="px-2">
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"/>
    </v-overlay>

    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <v-tabs v-model="selectedTab" background-color="transparent" grow centered>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-rh" @click="selectedTabMobile='tab-rh-mobile'; selectedRHTab='tab-rh-in-progress'">RH Protegido</v-tab>
        <v-tab href="#tab-usuarios" @click="selectedTabMobile='tab-usuarios-mobile'; selectedEuTab='tab-eu-in-progress'"
               v-if="resourcePermission()">Eu Protegido
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" class="backgroundColor" style="width: 100%">
        <DrawerFilterer title="Atendimentos" :filters="loadFiltersComputed" :data="items" @queryUpdate="loadItemupdate"
                        @filtersRemoved="loadInitialItems" @clearFilters="loadInitialItems"/>

        <v-tab-item :value="'tab-rh'">

          <v-flex xs12>
            <ProgressiveLoader :disabled="busy">
              <v-card>
                <v-card-title class="primary--text">
                  <span>Histórico</span>
                  <v-spacer/>
                  <v-btn fab bottom right fixed color="primary" dark @click="toggleFilterDrawer">
                    <v-icon>fas fa-filter</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-tabs v-model="selectedRHTab" background-color="transparent" grow centered>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-rh-in-progress">Em Andamento</v-tab>
                    <v-tab href="#tab-rh-closed">Finalizados</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="selectedRHTab" class="backgroundColor" style="width: 100%">
                    <v-tab-item :value="'tab-rh-in-progress'">
                      <v-card>
                        <v-data-table
                            id="atendimento-andamento-listar"
                            loading-text="Carregando itens..."
                            disable-filtering
                            disable-sort
                            :headers="headers"
                            :items="filteredItems"
                            :loading="loadingInProgress"
                            item-key="id"
                            hide-default-footer
                            :items-per-page="pageSize"
                            @click:row="(item) => handleItemSelection(item)"
                            class="elevation-1 centered-pagination"
                        >
                          <template v-slot:header>
                          </template>
                          <template v-slot:no-data>
                            <v-alert
                                :value="true"
                                type="info"
                                v-show="!loadingInProgress"
                                outlined
                            >Nenhum item foi encontrado
                            </v-alert>
                          </template>

                          <template v-slot:item.attendee="{ item }">
                            <span v-if="!item.attendee"> - </span>

                            <span v-else-if="item.attendee && item.attendee.length <= 50">
                      {{ item.attendee }}
                    </span>

                            <v-tooltip top v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ item.attendee.substring(0, 50) }}...</span>
                              </template>
                              <span>{{ item.attendee }}</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                        <v-pagination
                            :disabled="busy"
                            v-model="paginationRh.inProgressPage"
                            :length="paginationRh.inProgressTotalPages"
                            :total-visible="pageSize"
                            @input="loadInProgress(params)"
                        ></v-pagination>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item :value="'tab-rh-closed'">
                      <v-card>
                        <v-data-table
                            id="atendimento-fechado-listar"
                            loading-text="Carregando itens..."
                            disable-filtering
                            disable-sort
                            :headers="headers"
                            :items="filteredClosedItems"
                            :loading="loadingClosed"
                            hide-default-footer
                            item-key="id"
                            :items-per-page="pageSize"
                            @click:row="(item) => handleItemSelection(item)"
                            class="elevation-1 centered-pagination"
                        >
                          <template v-slot:header>
                          </template>
                          <template v-slot:no-data>
                            <v-alert
                                :value="true"
                                type="info"
                                v-show="!loadingClosed"
                                outlined
                            >Nenhum item foi encontrado
                            </v-alert>
                          </template>

                          <template v-slot:item.attendee="{ item }">
                            <span v-if="!item.attendee"> - </span>

                            <span v-else-if="item.attendee && item.attendee.length <= 50">
                      {{ item.attendee }}
                    </span>

                            <v-tooltip top v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ item.attendee.substring(0, 50) }}...</span>
                              </template>
                              <span>{{ item.attendee }}</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                        <v-pagination
                            :disabled="busy"
                            v-model="paginationRh.closedPage"
                            :length="paginationRh.closedTotalPages"
                            :total-visible="pageSize"
                            @input="loadClosed(params)"
                        ></v-pagination>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </ProgressiveLoader>
          </v-flex>
        </v-tab-item>

        <v-tab-item :value="'tab-usuarios'">
          <v-flex xs12>
            <ProgressiveLoader :disabled="busyEuProtegido">
              <v-card>
                <v-card-title class="primary--text">
                  <span>Histórico</span>
                  <v-spacer/>
                  <v-btn fab bottom right fixed color="primary" dark @click="toggleFilterDrawer">
                    <v-icon>fas fa-filter</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-tabs v-model="selectedEuTab" background-color="transparent" grow centered>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-eu-in-progress">Em Andamento</v-tab>
                    <v-tab href="#tab-eu-closed">Finalizados</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="selectedEuTab" class="backgroundColor" style="width: 100%">
                    <v-tab-item :value="'tab-eu-in-progress'">
                      <v-card>
                        <v-data-table
                            id="atendimento-euProtegido-andamento-listar"
                            loading-text="Carregando itens..."
                            disable-filtering
                            disable-sort
                            hide-default-footer
                            :headers="headers"
                            :items="filteredEuProtegidoItems"
                            :loading="loadingEuProtegido"
                            item-key="id"
                            :items-per-page="pageSize"
                            class="elevation-1 centered-pagination"
                        >
                          <template v-slot:header>
                          </template>
                          <template v-slot:no-data>
                            <v-alert
                                :value="true"
                                type="info"
                                v-show="!loadingEuProtegido"
                                outlined
                            >Nenhum item foi encontrado
                            </v-alert>
                          </template>
                        </v-data-table>
                        <v-pagination
                            :disabled="busy"
                            v-model="paginationEu.inProgressPage"
                            :length="paginationEu.inProgressTotalPages"
                            :total-visible="pageSize"
                            @input="loadEuProtegidoInProgress(params)"
                        ></v-pagination>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item :value="'tab-eu-closed'">
                      <v-card>
                        <v-data-table
                            id="atendimento-euProtegido-fechado-listar"
                            loading-text="Carregando itens..."
                            disable-filtering
                            disable-sort
                            hide-default-footer
                            :headers="headers"
                            :items="filteredEuProtegidoClosedItems"
                            :loading="loadingEuProtegidoClosed"
                            item-key="id"
                            :items-per-page="pageSize"
                            class="elevation-1 centered-pagination"
                        >
                          <template v-slot:header>
                          </template>
                          <template v-slot:no-data>
                            <v-alert
                                :value="true"
                                type="info"
                                v-show="!loadingEuProtegidoClosed"
                                outlined
                            >Nenhum item foi encontrado
                            </v-alert>
                          </template>
                        </v-data-table>
                        <v-pagination
                            :disabled="busy"
                            v-model="paginationEu.closedPage"
                            :length="paginationEu.closedTotalPages"
                            :total-visible="pageSize"
                            @input="loadEuProtegidoClosed(params)"
                        ></v-pagination>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>

                </v-card-text>
              </v-card>
            </ProgressiveLoader>
          </v-flex>
        </v-tab-item>
      </v-tabs-items>


    </v-layout>
    <template v-else>
      <v-tabs v-model="selectedTabMobile" background-color="transparent" grow centered>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-rh-mobile" @click="selectedTab='tab-rh'; selectedRHMobileTab='tab-rh-mobile-in-progress'">RH</v-tab>
        <v-tab href="#tab-usuarios-mobile" @click="selectedTab='tab-usuarios'; selectedEuMobileTab='tab-eu-mobile-in-progress'"
               v-if="resourcePermission()">Eu Protegido
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTabMobile" style="width: 100%">
        <FabFilterer title="Atendimentos" :filters="loadFiltersComputed" :data="items" @queryUpdate="loadItemupdate"
                     @filtersRemoved="loadInitialItems" @clearFilters="loadInitialItems"/>
        <v-tab-item :value="'tab-rh-mobile'">

          <ProgressiveLoader :disabled="busy">

            <v-tabs v-model="selectedRHMobileTab" background-color="transparent" grow centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-rh-mobile-in-progress">Em Andamento</v-tab>
              <v-tab href="#tab-rh-mobile-closed">Finalizados</v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedRHMobileTab" class="backgroundColor" style="width: 100%">
              <v-tab-item :value="'tab-rh-mobile-in-progress'">
                <v-data-iterator
                    hide-default-header
                    loading-text="Carregando itens..."
                    disable-filtering
                    disable-sort
                    :items="filteredItems"
                    :loading="loadingInProgress"
                    item-key="id"
                    :items-per-page="pageSize"
                    hide-default-footer
                    @click:row="(item) => handleItemSelection(item)"
                    class="elevation-1 centered-pagination"
                    style="background-color: white"
                >
                  <template v-slot:no-data>
                    <v-alert
                        :value="false"
                        type="info"
                        v-show="!loading"
                        outlined
                    >Nenhum item foi encontrado
                    </v-alert>
                  </template>
                  <template v-slot:default="props">
                    <v-row v-for="item in props.items" :key="item.id">
                      <v-col cols="1"></v-col>
                      <v-col cols="10">
                        <v-card @click="handleItemSelection(item)" class="elevation-5 pb-0">
                          <v-card-text class="pb-0">
                            <v-row dense class="mb-4">
                              <template v-for="header in headers">
                                <v-col :cols="header.cols" :key="header.value">
                                  <v-row no-gutters>
                                    <v-col cols="12" class="black--text font-weight-bold">{{ header.text }}</v-col>
                                    <v-col
                                        cols="12"
                                        :class="(header.bold ? 'font-weight-bold' : '') + ' silver--text pa-0'"
                                    >{{ item[header.value] }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>
                              <v-col cols="12" class="d-flex primary--text justify-center text-sm-center mb-1">
                                <label class="primary--text">
                                  Clique para detalhar
                                </label>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
                <v-pagination
                    :disabled="busy"
                    v-model="paginationRh.inProgressPage"
                    :length="paginationRh.inProgressTotalPages"
                    :total-visible="pageSize"
                    @input="loadInProgress(params)"
                ></v-pagination>
              </v-tab-item>

              <v-tab-item :value="'tab-rh-mobile-closed'">
                <v-data-iterator
                    hide-default-header
                    loading-text="Carregando itens..."
                    disable-filtering
                    disable-sort
                    :items="filteredClosedItems"
                    :loading="loadingClosed"
                    item-key="id"
                    :items-per-page="pageSize"
                    hide-default-footer
                    @click:row="(item) => handleItemSelection(item)"
                    class="elevation-1 centered-pagination"
                    style="background-color: white"
                >
                  <template v-slot:no-data>
                    <v-alert
                        :value="false"
                        type="info"
                        v-show="!loading"
                        outlined
                    >Nenhum item foi encontrado
                    </v-alert>
                  </template>
                  <template v-slot:default="props">
                    <v-row v-for="item in props.items" :key="item.id">
                      <v-col cols="12">
                        <v-card @click="handleItemSelection(item)" class="elevation-5 pb-0">
                          <v-card-text class="pb-0">
                            <v-row dense class="mb-4">
                              <template v-for="header in headers">
                                <v-col :cols="header.cols" :key="header.value">
                                  <v-row no-gutters>
                                    <v-col cols="12" class="black--text font-weight-bold">{{ header.text }}</v-col>
                                    <v-col
                                        cols="12"
                                        :class="(header.bold ? 'font-weight-bold' : '') + ' silver--text pa-0'"
                                    >{{ item[header.value] }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>
                              <v-col cols="12" class="d-flex primary--text justify-center text-sm-center mb-1">
                                <label class="primary--text">
                                  Clique para detalhar
                                </label>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
                <v-pagination
                    :disabled="busy"
                    v-model="paginationRh.closedPage"
                    :length="paginationRh.closedTotalPages"
                    :total-visible="pageSize"
                    @input="loadClosed(params)"
                ></v-pagination>
              </v-tab-item>
            </v-tabs-items>

          </ProgressiveLoader>
        </v-tab-item>

        <v-tab-item :value="'tab-usuarios-mobile'">
          <ProgressiveLoader :disabled="busyEuProtegido">

            <v-tabs v-model="selectedEuMobileTab" background-color="transparent" grow centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-eu-mobile-in-progress">Em Andamento</v-tab>
              <v-tab href="#tab-eu-mobile-closed">Finalizados</v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedEuMobileTab" class="backgroundColor" style="width: 100%">
              <v-tab-item :value="'tab-eu-mobile-in-progress'">
                <v-data-iterator
                    hide-default-header
                    loading-text="Carregando itens..."
                    disable-filtering
                    disable-sort
                    :items="filteredEuProtegidoItems"
                    :loading="loadingEuProtegido"
                    item-key="id"
                    :items-per-page="pageSize"
                    hide-default-footer
                    class="elevation-1 centered-pagination"
                    style="background-color: white"
                >
                  <template v-slot:no-data>
                    <v-alert
                        :value="false"
                        type="info"
                        v-show="!loadingEuProtegido"
                        outlined
                    >Nenhum item foi encontrado
                    </v-alert>
                  </template>
                  <template v-slot:default="props">
                    <v-row v-for="item in props.items" :key="item.id">
                      <v-col cols="12">
                        <v-card class="elevation-5 pb-0">
                          <v-card-text class="pb-0">
                            <v-row dense class="mb-4">
                              <template v-for="header in headers">
                                <v-col :cols="header.cols" :key="header.value">
                                  <v-row no-gutters>
                                    <v-col cols="12" class="black--text font-weight-bold">{{ header.text }}</v-col>
                                    <v-col
                                        cols="12"
                                        :class="(header.bold ? 'font-weight-bold' : '') + ' silver--text pa-0'"
                                    >{{ item[header.value] }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
                <v-pagination
                    :disabled="busy"
                    v-model="paginationEu.inProgressPage"
                    :length="paginationEu.inProgressTotalPages"
                    :total-visible="pageSize"
                    @input="loadEuProtegidoInProgress(params)"
                ></v-pagination>
              </v-tab-item>

              <v-tab-item :value="'tab-eu-mobile-closed'">
                <v-data-iterator
                    hide-default-header
                    loading-text="Carregando itens..."
                    disable-filtering
                    disable-sort
                    :items="filteredEuProtegidoClosedItems"
                    :loading="loadingEuProtegidoClosed"
                    item-key="id"
                    :items-per-page="pageSize"
                    hide-default-footer
                    class="elevation-1 centered-pagination"
                    style="background-color: white"
                >
                  <template v-slot:no-data>
                    <v-alert
                        :value="false"
                        type="info"
                        v-show="!loadingEuProtegidoClosed"
                        outlined
                    >Nenhum item foi encontrado
                    </v-alert>
                  </template>
                  <template v-slot:default="props">
                    <v-row v-for="item in props.items" :key="item.id">
                      <v-col cols="12">
                        <v-card class="elevation-5 pb-0">
                          <v-card-text class="pb-0">
                            <v-row dense class="mb-4">
                              <template v-for="header in headers">
                                <v-col :cols="header.cols" :key="header.value">
                                  <v-row no-gutters>
                                    <v-col cols="12" class="black--text font-weight-bold">{{ header.text }}</v-col>
                                    <v-col
                                        cols="12"
                                        :class="(header.bold ? 'font-weight-bold' : '') + ' silver--text pa-0'"
                                    >{{ item[header.value] }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
                <v-pagination
                    :disabled="busy"
                    v-model="paginationEu.closedPage"
                    :length="paginationEu.closedTotalPages"
                    :total-visible="pageSize"
                    @input="loadEuProtegidoClosed(params)"
                ></v-pagination>
              </v-tab-item>


            </v-tabs-items>
          </ProgressiveLoader>
        </v-tab-item>
      </v-tabs-items>

    </template>
  </v-container>
</template>

<style>
.centered-pagination .v-data-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Ajuste a margem superior conforme necessário */
}
</style>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import FabFilterer from "@/components/fab-filterer";
import DrawerFilterer from "@/components/drawer-filterer";
import ProgressiveLoader from "@/components/progressive-loader";
import CaseService from "../../services/odoo/CaseService";
import Vue from "vue";

export default {
  name: "AtendimentoListar",
  components: {FabFilterer, DrawerFilterer, ProgressiveLoader},
  data: () => ({
    case: {
      id: null,
      xipp_helpdesk_display_stage: null,
      stage_id: null,
      name: null,
      description: null,
      create_date: null,
      ticket_type_id: null,
      __last_update: null,
      partner_name: null,
      xipp_attendee_name: null,
      xipp_origin_id: null,
      partner_id: {
        name: null
      },
      consulting: {
        name: null,
      },
      xipp_contract_id: {
        benefit_id: null,
        policy: null,
        subcontract_number: null,
        executive_manager_id: {
          name: null,
        },
        contract_owner_id: {
          id: null,
          name: null,
          carrier_data_cancelation: null,
          cut_off_date: null,
          moderator_variable: null,
          due_date_invoice: null,
          legal_name: null,
          claim_limit: null,
          payment_mode: null,
          due_date_copay: null,
          start_date: null,
          financial_index: null,
          xipp_contributory_id: null,
        },
        carrier_id: {
          xipp_commercial_name: null
        }
      }
    },
    paginationRh: {
      inProgressPage: 1,
      inProgressTotalPages: 1,
      closedPage: 1,
      closedTotalPages: 1,
    },
    paginationEu: {
      inProgressPage: 1,
      inProgressTotalPages: 1,
      closedPage: 1,
      closedTotalPages: 1,
    },
    pageSize: 20,
    itemsPerPage: 20,
    items: [],
    itemsEuProtegido: [],
    filteredItems: [],
    filteredClosedItems: [],
    filteredEuProtegidoItems: [],
    filteredEuProtegidoClosedItems: [],
    loading: true,
    loadingInProgress: false,
    loadingClosed: false,
    loadingEuProtegido: false,
    loadingEuProtegidoClosed: false,
    search: "",
    selectedTab: "tab-rh",
    selectedTabMobile: "tab-rh-mobile",
    selectedRHTab: "tab-rh-in-progress",
    selectedRHMobileTab: "tab-rh-mobile-in-progress",
    selectedEuTab: "tab-eu-in-progress",
    selectedEuMobileTab: "tab-eu-mobile-in-progress",
    busy: true,
    busyEuProtegido: true,
    paginations: 0,
    maximumItems: 0,
    loadedItems: 0,
    describe: null,
    pageNext: null,
    filters: [],
    params: "",
    status: [],
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapState(["window"]),
    loadFiltersComputed() {
      return this.filters
    },
    headers() {
      let baseHeaders = []
      if (this.selectedTab.includes("tab-rh")) {
        baseHeaders = [
          {text: "Protocolo", value: "protocolo", cols: "6"},
          {text: "Solicitação", value: "type", cols: "6"},
          {text: "Contrato", value: "contractName", cols: "12"},
          {text: "Atendido", value: "attendee", cols: "6"},
          {text: "Abertura", value: "abertura", cols: "6"},
          {text: "Última Alteração", value: "lastModified", cols: "6"},
          {text: "Status", value: "status", cols: "6"},
          {text: "Solicitante", value: "creatorName", cols: "6"}
        ];
      } else {
        baseHeaders = [
          {text: "Protocolo", value: "protocolo", cols: "6"},
          {text: "Solicitação", value: "type", cols: "6"},
          {text: "Contrato", value: "contractName", cols: "12"},
          {text: "Atendido", value: "attendeeName", cols: "12"},
          {text: "Abertura", value: "abertura", cols: "6"},
          {text: "Última Alteração", value: "lastModified", cols: "6"},
          {text: "Status", value: "status", cols: "6"},
          {text: "Solicitante", value: "creatorName", cols: "6"}
        ];
      }
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({text: "", value: "", sortable: false, cols: ""});
      }
      return baseHeaders;
    },
    defaultQuery() {
      return '?isClosed=false';
    },
    defaultQueryOpen() {
      return '&isClosed=false';
    },
    defaultQueryClosed() {
      return '&isClosed=true';
    },
    defaultQueryFields(){
      return '&fields=id,create_date,partner_id,partner_id.name,stage_id,xipp_helpdesk_display_stage,ticket_type_id,name,xipp_attendee_name,' +
          'xipp_external_protocol,xipp_contract_id,xipp_contract_id.policy,partner_name,__last_update,xipp_name_web';
    }
  },
  methods: {
    ...mapMutations(["showmsg"]),
    addItem(obj) {
      this.items.push(obj);
      this.filteredItems.push(obj);
    },
    addClosedItem(obj) {
      this.items.push(obj);
      this.filteredClosedItems.push(obj);
    },
    addEuProtegidoItem(obj) {
      this.itemsEuProtegido.push(obj);
      this.filteredEuProtegidoItems.push(obj);
    },
    addEuProtegidoClosedItem(obj) {
      this.itemsEuProtegido.push(obj);
      this.filteredEuProtegidoClosedItems.push(obj);
    },
    setFilteredItems(newItems) {
      this.items = [];
      this.filteredItems = [];
      for (const item of newItems) {
        item.userCase = false;
        this.addItem(this.configureItem(item));
      }
    },
    setFilteredClosedItems(newItems) {
      this.items = [];
      this.filteredClosedItems = [];
      for (const item of newItems) {
        item.userCase = false;
        this.addClosedItem(this.configureItem(item));
      }
    },
    setEuProtegidoItens(newItems) {
      this.itemsEuProtegido = [];
      this.filteredEuProtegidoItems = [];
      for (const item of newItems) {
        item.userCase = true;
        this.addEuProtegidoItem(this.configureItem(item));
      }
    },
    setEuProtegidoClosedItens(newItems) {
      this.itemsEuProtegido = [];
      this.filteredEuProtegidoClosedItems = [];
      for (const item of newItems) {
        item.userCase = true;
        this.addEuProtegidoClosedItem(this.configureItem(item));
      }
    },
    handleItemSelection(item) {
      if (this.selectedTab.includes("tab-rh")) {
        this.$router.push({name: "atendimento", params: {id: item.id, externalId: item.xipp_external_protocol}});
      } else {
        this.$router.push({
          name: "atendimento",
          params: {id: item.id, appCode: "eu_protegido", externalId: item.xipp_external_protocol}
        });
      }

    },
    configureItem(data) {
      let item_r;
      if (!data.userCase) {
        this.case = {...data}
        item_r = {
          id: data.id,
          protocolo: data.id,
          abertura: Vue.filter('formatDate')(data.create_date),
          contractName: data.xipp_contract_id ? data.xipp_contract_id.policy + " - " + data.partner_id.name : "",
          accountName: data.partner_id ? data.partner_id.name : "",
          status: data.xipp_helpdesk_display_stage ? data.xipp_helpdesk_display_stage : data.stage_id,
          type: data.ticket_type_id ? data.ticket_type_id : data.name,
          subject: data.name ? data.name : "",
          lastModified: Vue.filter('formatDate')(data.__last_update),
          attendee: data.xipp_attendee_name ? data.xipp_attendee_name : '',
          xipp_external_protocol: data.xipp_external_protocol,
          creatorName: data.xipp_name_web
        };
      } else {
        item_r = {
          id: data.id,
          protocolo: data.id,
          abertura: Vue.filter('formatDate')(data.create_date),
          contractName: data.xipp_contract_id ? data.xipp_contract_id.policy + " - " + data.partner_id.name : "",
          accountName: data.partner_id ? data.partner_id.name + " - " + data.partner_name : "",
          attendeeName: data.xipp_attendee_name ? data.xipp_attendee_name : "",
          status: data.xipp_helpdesk_display_stage ? data.xipp_helpdesk_display_stage : data.stage_id,
          type: data.ticket_type_id ? data.ticket_type_id : data.name,
          subject: data.name ? data.name : "",
          lastModified: Vue.filter('formatDate')(data.__last_update),
          xipp_external_protocol: data.xipp_external_protocol,
          creatorName: data.xipp_name_web
        };
      }

      const contract = data.contract;
      if (contract) {
        if (contract.policy) {
          item_r.accountName = contract.policy + " - " + item_r.accountName;
        }
      }

      return item_r;
    },
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    async loadItemupdate(data) {
      if (data) {
        data = data.trim();
        // parametros do requests
        var rq = [];

        //recuperar parametros a partir da query
        const filters = data.trim().split("andd");

        //percorrer query e montar os parametros
        filters.forEach(fil => {
          fil = fil.trim();

          // criar parametro
          let q = fil.split("in ");
          if (q[0] && q[0].length > 0) {
            // montar chave e valor
            rq.push({chave: q[0], valor: q[1]});
          }
        });

        // tratando os parametrosrequest
        rq.forEach(res => {
          res.chave = res.chave.trim();
          res.valor = res.valor.trim();
          res.valor = res.valor.replace("(", "");
          res.valor = res.valor.replace(")", "");

          // replace so tira um por vez
          for (let index = 0; index < 20; index++) {
            res.valor = res.valor.replace("'", "");
          }
        });

        let path = "";
        // passar parametros de filtros
        rq.forEach(parametro => {
          console.log("Valor:"+parametro.valor);
          if(parametro.valor) {
            if (path && path.length > 0) {
              path += "&";
            } else {
              path = "?";
            }
            parametro.chave = parametro.chave.replace("CaseNumber", "caseNumber");
            parametro.chave = parametro.chave.replace("Status", "status");
            path += parametro.chave;
            path += "=" + parametro.valor;
          }
        });
        this.params = path;

        this.loadItemsRH(path);
        this.loadItemsEuProtegido(path);
      }
    },

    async startItemsRH(params = "") {
      if (this.filteredItems.length === 0 && this.filteredClosedItems.length === 0 && (!this.loadingInProgress && !this.loadingClosed)) {
        await this.loadItemsRH(params);
      }
    },
    async loadItemsRH(params = "") {
      await Promise.all([this.loadInProgress(params), this.loadClosed(params)]);
    },
    async loadInProgress(params) {
      this.setFilteredItems([]);
      let containsStatus = false;
      if (params && params.length > 0) {
        containsStatus = params.includes("status=closed");
        params += `&pageNumber=${this.paginationRh.inProgressPage}&pageSize=${this.pageSize}${this.defaultQueryOpen}`
      } else {
        params = `?pageNumber=${this.paginationRh.inProgressPage}&pageSize=${this.pageSize}${this.defaultQueryOpen}`
      }
      if (!containsStatus) {
        this.busy = true;
        this.loadingInProgress = true;
        params += this.defaultQueryFields
        this._caseService
            .FindByParam(params)
            .then(res => {
              if (res.data.content) {
                let pages = parseInt(Math.ceil(res.data.totalElements / this.pageSize))
                this.paginationRh.inProgressTotalPages = pages > 0 ? pages : 1;
                this.setFilteredItems(res.data.content);
              }
            })
            .finally(() => {
              this.loading = false;
              this.loadingInProgress = false;
              this.busy = false;
            });
      }
    },
    async loadClosed(params) {
      this.setFilteredClosedItems([]);
      let containsStatus = false;
      if (params && params.length > 0) {
        containsStatus = params.includes("status=in_progress") || params.includes("status=draft");
        params += `&pageNumber=${this.paginationRh.closedPage}&pageSize=${this.pageSize}${this.defaultQueryClosed}`
      } else {
        params = `?pageNumber=${this.paginationRh.closedPage}&pageSize=${this.pageSize}${this.defaultQueryClosed}`
      }
      if (!containsStatus) {
        this.busy = true;
        this.loadingClosed = true;
        params += this.defaultQueryFields
        this._caseService
            .FindByParam(params)
            .then(res => {
              if (res.data.content) {
                let pages = parseInt(Math.ceil(res.data.totalElements / this.pageSize))
                this.paginationRh.closedTotalPages = pages > 0 ? pages : 1;
                this.setFilteredClosedItems(res.data.content);
              }
            })
            .finally(() => {
              this.loading = false;
              this.loadingClosed = false;
              this.busy = false;
            });
      }
    },
    async loadEuProtegidoInProgress(params = "") {
      this.setEuProtegidoItens([]);
      this.loadingEuProtegido = true;
      this.busyEuProtegido = true;
      let containsStatus = false;
      if (params && params.length > 0) {
        containsStatus = params.includes("status=closed");
        params += `&pageNumber=${this.paginationEu.inProgressPage}&pageSize=${this.pageSize}${this.defaultQueryOpen}&appCode=eu_protegido`
      } else {
        params = `?pageNumber=${this.paginationEu.inProgressPage}&pageSize=${this.pageSize}${this.defaultQueryOpen}&appCode=eu_protegido`
      }
      if (!containsStatus) {
        params += this.defaultQueryFields
        this._caseService
            .FindByParam(params)
            .then(res => {
              if (res.data.content) {
                let pages = parseInt(Math.ceil(res.data.totalElements / this.pageSize))
                this.paginationEu.inProgressTotalPages = pages > 0 ? pages : 1;
                this.setEuProtegidoItens(res.data.content);
              }
            })
            .finally(() => {
              this.loadingEuProtegido = false;
              this.busyEuProtegido = false;
            });
      }
    },
    async loadEuProtegidoClosed(params) {
      this.setEuProtegidoClosedItens([]);
      let containsStatus = false;
      if (params && params.length > 0) {
        containsStatus = params.includes("status=in_progress") || params.includes("status=draft");
        params += `&pageNumber=${this.paginationRh.closedPage}&pageSize=${this.pageSize}${this.defaultQueryClosed}&appCode=eu_protegido`
      } else {
        params = `?pageNumber=${this.paginationRh.closedPage}&pageSize=${this.pageSize}${this.defaultQueryClosed}&appCode=eu_protegido`
      }
      if (!containsStatus) {
        this.busyEuProtegido = true;
        this.loadingEuProtegidoClosed = true;
        params += this.defaultQueryFields
        this._caseService
            .FindByParam(params)
            .then(res => {
              if (res.data.content) {
                let pages = parseInt(Math.ceil(res.data.totalElements / this.pageSize))
                this.paginationEu.closedTotalPages = pages > 0 ? pages : 1;
                this.setEuProtegidoClosedItens(res.data.content);
              }
            })
            .finally(() => {
              this.loadingEuProtegido = false;
              this.loadingEuProtegidoClosed = false;
              this.busyEuProtegido = false;
            });
      }
    },
    async startItemsEuProtegido(params = '') {
      if (this.filteredEuProtegidoItems.length === 0 && this.filteredEuProtegidoClosedItems.length === 0 && (!this.loadingEuProtegido && !this.loadingEuProtegidoClosed)) {
        this.loadItemsEuProtegido(params);
      }
    },
    async loadItemsEuProtegido(params = "") {
      await Promise.all([this.loadEuProtegidoInProgress(params), this.loadEuProtegidoClosed(params)]);
    },
    async loadInitialItems() {
        await this.loadItemsRH();
        await this.loadItemsEuProtegido();
    },
    async loadFilters() {

      this.filters.push({
            name: "Protocolo",
            key: "id",
            active: false,
            allowClose: false,
            types: [
              {
                name: "Número do protocolo",
                value: "",
                type: "input",
                active: true
              }
            ]
          },
          {
            name: "Protocolo - ...",
            key: "externalProtocol",
            active: false,
            allowClose: false,
            types: [
              {
                name: "Protocolo",
                value: "",
                type: "input",
                active: true
              }
            ]
          },
          {
            name: "Data de Abertura",
            key: "monthPeriods",
            types: [
              {
                name: "Este mês",
                value: `THIS_MONTH`,
                type: "select",
                active: false,
                quotes: false
              },
              {
                name: "Mês passado",
                value: `LAST_MONTH`,
                type: "select",
                active: false,
                quotes: false
              },
              {
                name: "Este trimestre",
                value: `THIS_QUARTER`,
                type: "select",
                active: false,
                quotes: false
              }
            ]
          },
          {
            name: "Status",
            key: "status",
            types: [
              {
                name: "Em Andamento",
                value: "in_progress",
                type: "select",
                active: false,
                selected: "in_progress"
              },
              {name: "Novos", value: "draft", type: "select", active: true, selected: "draft"}
            ]
          }
      );

      let caseTypes =
          this.user.menu
              .filter(menu => menu.id == this.$store.state.currentMenu.id)
              .map(menu => menu.subMenus)
              .reduce(Array)
              .map(menu => ({
                name: menu.title,
                value: menu.options,
                type: "select",
                active: true,
                selected: true
              }));

      if (caseTypes.length) {
        let caseTypesFilter = {
          name: "Solicitação",
          key: "typeCode",
          types: caseTypes
        };

        this.filters.push(caseTypesFilter);
      }

      var usersFinancialGroup = [{
        id: this.user.name,
        value: this.user.name,
        text: "Meus Atendimentos"
      }];

      this._caseService
          .FindByParam('?clientViewPermission%5B0%5D.code=rh_protegido&clientViewPermission%5B1%5D.code=eu_protegido&fields=xipp_name_web')
          .then(res => {
            if (res.data.content) {
              let content = res.data.content.sort((p1, p2) => (p1.xipp_name_web < p2.xipp_name_web) ? -1 : (p1.xipp_name_web > p2.xipp_name_web) ? 1 : 0);
              content.forEach(c => {
                if(c.xipp_name_web && !usersFinancialGroup.includes(c.xipp_name_web)){
                  usersFinancialGroup.push({
                    id: c.xipp_name_web,
                    value: c.xipp_name_web,
                    text: c.xipp_name_web
                  });
                }
              });
            }
          });

      let usersFinancialGroupFilter = usersFinancialGroup
      let usersFilter = {
        name: "Solicitante",
        key: "nameWeb",
        types: [
          {
            name: "Selecione um Solicitante",
            value: "",
            type: "combobox",
            items: usersFinancialGroupFilter,
            hasSubItem: false,
            active: true,
            clearable: true,
            multiple: true
          }
        ]
      };

      this.filters.push(usersFilter);

      if (this.resourcePermission()) {
        this.filters.push(
            {
              name: "Usuários",
              key: "attendeeName",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Nome do Usuário",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
        )
      }
    },
    resourcePermission() {
      let caseTypes =
          this.user.menu
              .filter(menu => menu.id == this.$store.state.currentMenu.id)
              .map(menu => menu.subMenus)
              .reduce(Array)
              .map(menu => ({
                title: menu.title,
                icon: menu.icon,
                type: menu.options
              }));

      this.cards = Array.from(caseTypes);
      let index = this.cards.findIndex(menu => menu.title === 'ATENDIMENTOS - USUÁRIOS');
      if (index && index > -1) {
        return true;
      }
      return false;
    },
    closeSideDrawer() {
      this.$store.commit('setFilterDrawer', false);
      this.$store.commit('setAbledToSearch', true);
    },
    async loadLimiteDateExternalProtocol() {
      await this._caseService.FindLimiteDateExternalProtocol()
          .then((response) => {
            if (response && response.data) {
              if (this.filters.length) {
                this.filters[1].name = 'Protocolo - ' + response.data;
                this.filters[1].types[0].name = 'Protocolo até ' + response.data;
              }
            }
          });
    }
  },
  async beforeMount() {
    this.loadFilters();
  },
  async created() {
    this._caseService = new CaseService();
    this.closeSideDrawer();
    await this.loadLimiteDateExternalProtocol();
    await this.loadItemsRH();
    await this.loadItemsEuProtegido();
  }
};
</script>

<style>
#atendimento-andamento-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}

#atendimento-fechado-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}
</style>
